import React from 'react';

import classes from './styles.module.css';
const Grid = (props) => {
    
    return (
        <div className={classes.list}>
            {props.children}
        </div>
    );
};

export default Grid;