import React from 'react';
import Modal from '../Modal';
import CurrentSSLDetails from './CurrentSSLDetails';

import classes from './styles.module.css';

const SSLDetails = (props) => {

    return (
        <Modal onClose={props.onClose}>
            <CurrentSSLDetails domain={props.domain} onClose={props.onClose} />
        </Modal>
    );
};

export default SSLDetails;