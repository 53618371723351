import React from 'react';
import { formatDateTime, expire } from '../../services/Format/Date';

import classes from './styles.module.css';

const SSLItem = (props) => {
    const { domain: {
        domainName,
        SSL
    } } = props;
    const date = SSL.expires ? formatDateTime(SSL.expires) : ' --/--/----';

    const isExpiring = expire(SSL.expires) ? classes.expiring : null;

    return (
        <div className={classes.item}>
            <div className={classes.domainName}>
                <h1>{domainName}</h1>
            </div>
            <section className={classes.content}>
                <p>Provider: <span>{SSL.provider || '--'}</span></p>
                <p className={isExpiring}>Expires on: <span>{date}</span></p>
            </section>
        </div>
    );
};

export default SSLItem;