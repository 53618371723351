import React from 'react';

import Auth from '../components/pages/Auth';

const Authpage = (props) => {
    
    return (
        <Auth />
    );
};

export default Authpage;