import React, { useEffect, useCallback, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import useDB from '../../../hooks/useDB';

import Grid from '../../Grid';
import GridItem from '../../GridItem';
import DashboardItem from '../../DashboardItem';
import Search from '../../Search';

import classes from './styles.module.css';

const Dashboard = (props) => {
    const [domains, setDomains] = useState([]);
    const [hosting, setHosting] = useState([]);
    const [SSL, setSSL] = useState([]);
    const navigate = useNavigate();
    const { readAll } = useDB();
    const auth = getAuth();
    const fetchAssets = useCallback(async () => {
        onAuthStateChanged(auth, async user => {
            console.log('fetching started')
            if (user) {
                try {
                    const domainsList = await readAll('domains', user.uid);
                    setDomains(domainsList);
                    const SSLlist = domainsList.filter(domain => domain.SSL.expires !== null && domain.SSL.expires !== '');
                    setSSL(SSLlist);
                    const hostingList = await readAll('hosting', user.uid);
                    setHosting(hostingList);
                } catch (error) {
                    console.error(error);
                }
            }
        })
    }, []);


    useEffect(() => {
        fetchAssets();
    }, [fetchAssets]);

    let expiringDomains = 0;
    let expiringHosting = 0;
    let expiringSSL = 0;
    const month = 30 * 24 * 60 * 60 * 1000;
    domains.forEach(domain => {
        if ((Date.parse(domain.registrar.expires) - Date.now()) < month) expiringDomains++;
        if ((Date.parse(domain.SSL.expires) - Date.now()) < month) expiringSSL++;
    });
    hosting.forEach(hosting => {
        if ((Date.parse(hosting.expires) - Date.now()) < month) expiringHosting++;
    });


    const viewDetailsHandler = asset => {
        // redirect to asset
        navigate(`/${asset}`)
    };

    return (
        <div className={classes.wrapper}>
            <h1 className={classes.title}>Dashboard</h1>
            <Search />
            <div className={classes.group}>
                <h1>EXPIRING WITHIN 30 DAYS:</h1>
                <Grid>
                    <GridItem onClick={() => viewDetailsHandler("domains")} footer='View details &rarr;'>
                        <DashboardItem asset="Domains" amount={expiringDomains} />
                    </GridItem>
                    <GridItem onClick={() => viewDetailsHandler("hosting")} footer='View details &rarr;'>
                        <DashboardItem asset="Hosting" amount={expiringHosting} />
                    </GridItem>
                    <GridItem onClick={() => viewDetailsHandler("ssl")} footer='View details &rarr;'>
                        <DashboardItem asset="SSL" amount={expiringSSL} />
                    </GridItem>
                </Grid>
            </div>
            <div className={classes.group}>
                <h1>TOTAL</h1>
                <Grid>
                    <GridItem onClick={() => viewDetailsHandler("domains")} footer='View details &rarr;'>
                        <DashboardItem asset="Domains" amount={domains.length} />
                    </GridItem>
                    <GridItem onClick={() => viewDetailsHandler("hosting")} footer='View details &rarr;'>
                        <DashboardItem asset="Hosting" amount={hosting.length} />
                    </GridItem>
                    <GridItem onClick={() => viewDetailsHandler("ssl")} footer='View details &rarr;'>
                        <DashboardItem asset="SSL" amount={SSL.length} />
                    </GridItem>
                </Grid>
            </div>

        </div>
    );
};

export default Dashboard;