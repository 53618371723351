import React, { useEffect, useRef, useState, useCallback } from 'react';
import useDB from '../../../hooks/useDB';
import { formatInputDate } from '../../../services/Format/Date';


import classes from './styles.module.css';

const CurrentHostingDetails = props => {
    const [isEdited, setIsEdited] = useState(false);
    const [hosting, setHosting] = useState(null);
    const { removeHosting, saveHosting, readHosting } = useDB();


    //refs for inputs
    const hostingNameRef = useRef();
    const hostingUrlRef = useRef();
    const hostingUnameRef = useRef();
    const hostingPassRef = useRef();
    const hostingExpiresRef = useRef();
    const controlUrlRef = useRef();
    const controlUnameRef = useRef();
    const controlPassRef = useRef();
    const ftpUrlRef = useRef();
    const ftpUnameRef = useRef();
    const ftpPassRef = useRef();
    const ftpCommentsRef = useRef();

    let hostingData = hosting || props.hosting;
    const { name } = props.hosting;
    const { url, uname, pass, expires, control_panel, FTP } = hostingData;
    const populateData = useCallback(async () => {
        try {
            console.log(name)
            const hostingDataServer = await readHosting(name, 'name');
            setHosting(hostingDataServer);
            console.log('read from db')
        } catch (error) {
            console.error(error)
            console.log('no response from server')
            setHosting(props.hosting);
        }
    }, [name]);
    useEffect(() => {
        populateData();
    }, [populateData])
    const empty = '';

    const readHostingChanges = () => {
        const control_panel = {
            url: controlUrlRef.current.value,
            uname: controlUnameRef.current.value,
            pass: controlPassRef.current.value
        };
        const FTP = {
            url: ftpUrlRef.current.value,
            uname: ftpUnameRef.current.value,
            pass: ftpPassRef.current.value,
            comments: ftpCommentsRef.current.value
        };
        return {
            id: hostingNameRef.current.value,
            name: hostingNameRef.current.value,
            url: hostingUrlRef.current.value,
            uname: hostingUnameRef.current.value,
            pass: hostingPassRef.current.value,
            expires: hostingExpiresRef.current.value,
            control_panel,
            FTP
        }
    };

    const saveDetails = async hosting => {
        try {
            await saveHosting(hosting);
        } catch (error) {
            console.error(error);
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        let inputs = [...document.getElementsByTagName('input'), ...document.getElementsByTagName('textarea')];
        inputs.forEach(input => {
            input.readOnly = true
        });
        const newHostingDetails = readHostingChanges();
        setIsEdited(false);
        // save to db
        saveDetails(newHostingDetails);
    };

    const editHandler = () => {
        let inputs = [...document.getElementsByTagName('input'), ...document.getElementsByTagName('textarea')];
        inputs.forEach(input => input.readOnly = false);
        setIsEdited(true);
    };

    const removeHandler = () => {
        removeHosting(name);
        props.onClose();
        populateData();
    };
    return (
        <div className={classes.details}>
            <form onSubmit={submitHandler}>
                <table>
                    <thead>
                        <tr>
                            <th colSpan='2'>{name}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                Hosting
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingName'>
                                    Name:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='hostingName'
                                    name='hostingName'
                                    readOnly
                                    defaultValue={hosting?.name || empty}
                                    ref={hostingNameRef}
                                />
                            </td>
                        </tr>
                        <tr className={classes.high}>
                            <td className={classes.title}>
                                <label htmlFor='hostingUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                {!isEdited && <a href={hosting?.url || null} target='_blank' rel='noreferrer'>{hosting?.url || null}</a>}
                                {isEdited && <input
                                    id='hostingUrl'
                                    name='hostingUrl'
                                    type='url'
                                    // readOnly
                                    defaultValue={hosting?.url || empty}
                                    ref={hostingUrlRef}
                                />}
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='hostingUname'
                                    name='hostingUname'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.uname || empty}
                                    ref={hostingUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='hostingPass'
                                    name='hostingPass'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.pass || empty}
                                    ref={hostingPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingExpires'>
                                    Expires on:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='hostingExpires'
                                    name='hostingExpires'
                                    type='date'
                                    readOnly
                                    defaultValue={hosting?.expires ? formatInputDate(hosting?.expires) : empty}
                                    ref={hostingExpiresRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                Control Panel
                            </td>
                        </tr>
                        <tr className={classes.high}>
                            <td className={classes.title}>
                                <label htmlFor='controlUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                {!isEdited && <a href={hosting?.control_panel?.url || null} target='_blank' rel='noreferrer'>{hosting?.control_panel?.url || null}</a>}
                                {isEdited && <input
                                    id='controlUrl'
                                    name='controlUrl'
                                    type='url'
                                    // readOnly
                                    defaultValue={hosting?.control_panel?.url || empty}
                                    ref={controlUrlRef}
                                />}
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='controlUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='controlUname'
                                    name='controlUname'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.control_panel?.uname || empty}
                                    ref={controlUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='controlPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='controlPass'
                                    name='controlPass'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.control_panel?.pass || empty}
                                    ref={controlPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                FTP
                            </td>
                        </tr>
                        <tr className={classes.high}>
                            <td className={classes.title}>
                                <label htmlFor='ftpUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                {!isEdited && <a href={hosting?.FTP?.url || null} target='_blank' rel='noreferrer'>{hosting?.FTP?.url || null}</a>}
                                {isEdited && <input
                                    id='ftpUrl'
                                    name='ftpUrl'
                                    type='url'
                                    // readOnly
                                    defaultValue={hosting?.FTP?.url || empty}
                                    ref={ftpUrlRef}
                                />}
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='ftpUname'
                                    name='ftpUname'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.FTP?.uname || empty}
                                    ref={ftpUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='ftpPass'
                                    name='ftpPass'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.FTP?.pass || empty}
                                    ref={ftpPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpComments'>
                                    Comments:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='ftpComments'
                                    name='ftpComments'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.FTP?.comments || empty}
                                    ref={ftpCommentsRef}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {isEdited && <button type='submit'>Save</button>}
            </form>
            <div className={classes.actions}>
                {!isEdited && <button onClick={editHandler}>Edit</button>}
                {!isEdited && <button onClick={removeHandler}>Remove</button>}
            </div>
        </div>
    )
};

export default CurrentHostingDetails;