import React, { useEffect, useRef, useState, useCallback } from 'react';
import useDB from '../../../hooks/useDB';
import { formatInputDate } from '../../../services/Format/Date';


import classes from './styles.module.css';

const CurrentDomainDetails = props => {
    const [isEdited, setIsEdited] = useState(false);
    const [domain, setDomain] = useState(null);
    const [hosting, setHosting] = useState(null);
    const { readDomain, saveDomain, removeDomain, saveHosting, readHosting } = useDB();


    //refs for inputs
    const registrarNameRef = useRef();
    const registrarUrlRef = useRef();
    const registrarUnameRef = useRef();
    const registrarPassRef = useRef();
    const registrarExpiresRef = useRef();
    const dns1Ref = useRef();
    const dns2Ref = useRef();
    const dns3Ref = useRef();
    const dns4Ref = useRef();
    const hostingNameRef = useRef();
    const hostingUrlRef = useRef();
    const hostingUnameRef = useRef();
    const hostingPassRef = useRef();
    const hostingExpiresRef = useRef();
    const sslProviderRef = useRef();
    const sslUnameRef = useRef();
    const sslPassRef = useRef();
    const sslExpiresRef = useRef();
    const controlUrlRef = useRef();
    const controlUnameRef = useRef();
    const controlPassRef = useRef();
    const ftpUrlRef = useRef();
    const ftpUnameRef = useRef();
    const ftpPassRef = useRef();
    const ftpCommentsRef = useRef();
    const commentsRef = useRef();

    let domainData = domain || props.domain;
    const { domainName } = props.domain;
    const { id, registrar, DNS, SSL, comments } = domainData;
    const populateData = useCallback(async () => {
        try {
            const domainDataServer = await readDomain(domainName);
            if (domainDataServer.hosting) {
                const hostingDataServer = await readHosting(domainDataServer.hosting);
                setHosting(hostingDataServer);
            }
            setDomain(domainDataServer);
            console.log('read from db')
        } catch (error) {
            console.error(error)
            setDomain(props.domain);
            setHosting(props.hosting);
        }
    }, [domainName]);
    useEffect(() => {
        populateData();
    }, [populateData])
    const empty = '';

    const readDomainChanges = (id, domainName) => {
        const registrar = {
            name: registrarNameRef.current.value,
            url: registrarUrlRef.current.value,
            uname: registrarUnameRef.current.value,
            pass: registrarPassRef.current.value,
            expires: registrarExpiresRef.current.value
        };
        const DNS = {
            DNS1: dns1Ref.current.value,
            DNS2: dns2Ref.current.value,
            DNS3: dns3Ref.current.value,
            DNS4: dns4Ref.current.value
        };
        const hosting = {
            
        };
        const SSL = {
            provider: sslProviderRef.current.value,
            uname: sslUnameRef.current.value,
            pass: sslPassRef.current.value,
            expires: sslExpiresRef.current.value
        };
        
        const comments = commentsRef.current.value;
        return {
            id,
            domainName,
            registrar,
            DNS,
            hosting,
            SSL,
            comments
        };
    };

    const readHostingChanges = () => {
        const control_panel = {
            url: controlUrlRef.current.value,
            uname: controlUnameRef.current.value,
            pass: controlPassRef.current.value
        };
        const FTP = {
            url: ftpUrlRef.current.value,
            uname: ftpUnameRef.current.value,
            pass: ftpPassRef.current.value,
            comments: ftpCommentsRef.current.value
        };
        return {
            id: hostingNameRef.current.value,
            name: hostingNameRef.current.value,
            url: hostingUrlRef.current.value,
            uname: hostingUnameRef.current.value,
            pass: hostingPassRef.current.value,
            expires: hostingExpiresRef.current.value,
            control_panel,
            FTP
        }
    };

    const saveDetails = async (domain, hosting) => {
        let hostingRef = null;
        try {
            if (hosting.name) {
                hostingRef = await saveHosting(hosting);
            }
            domain.hosting = hostingRef;
            await saveDomain(domain);
        } catch (error) {
            console.error(error);
        }
    }
    const submitHandler = e => {
        e.preventDefault();
        let inputs = [...document.getElementsByTagName('input'), ...document.getElementsByTagName('textarea')];
        inputs.forEach(input => {
            input.readOnly = true
        });
        const newCurrentDomainDetails = readDomainChanges(id, domainName);
        const newHostingDetails = readHostingChanges();
        setIsEdited(false);
        // save to db
        saveDetails(newCurrentDomainDetails, newHostingDetails);
    };

    const editHandler = () => {
        let inputs = [...document.getElementsByTagName('input'), ...document.getElementsByTagName('textarea')];
        inputs.forEach(input => input.readOnly = false);
        setIsEdited(true);
    };
    const removeHandler = () => {
        removeDomain(domainName);
        props.onClose();
    };
    return (
        <div className={classes.details}>
            <form onSubmit={submitHandler}>
                <table>
                    <thead>
                        <tr>
                            <th colSpan='2'>{domainName}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                Registrar
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='registrarName'>
                                    Name:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='registrarName'
                                    name='registrarName'
                                    readOnly
                                    defaultValue={registrar?.name || empty}
                                    ref={registrarNameRef}
                                />
                            </td>
                        </tr>
                        <tr className={classes.high}>
                            <td className={classes.title}>
                                <label htmlFor='registrarUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                {!isEdited && <a href={registrar?.url || null} target='_blank' rel='noreferrer'>{registrar?.url || null}</a>}
                                {isEdited && <input
                                    id='registrarUrl'
                                    name='registrarUrl'
                                    type='url'
                                    // readOnly
                                    defaultValue={registrar?.url || empty}
                                    ref={registrarUrlRef}
                                />}
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='registrarUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='registrarUname'
                                    name='registrarUname'
                                    type="text"
                                    readOnly
                                    defaultValue={registrar?.uname || empty}
                                    ref={registrarUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='registrarPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='registrarPass'
                                    name='registrarPass'
                                    type="text"
                                    readOnly
                                    defaultValue={registrar?.pass || empty}
                                    ref={registrarPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='RegistrarExpires'>
                                    Expires on:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='registrarExpires'
                                    name='registrarExpires'
                                    type='date'
                                    readOnly
                                    defaultValue={registrar?.expires? formatInputDate(registrar.expires) : empty}
                                    ref={registrarExpiresRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                DNS
                            </td>
                        </tr>
                        <tr className={classes.high}>
                            <td className={classes.title}>
                                <label htmlFor="dns1">
                                    DNS1:
                                </label>
                            </td>
                            <td className={classes.value}>
                                {!isEdited && <a href={`https://${DNS?.DNS1}` || ''} target='_blank' rel='noreferrer'>{DNS?.DNS1 || ''}</a>}
                                {isEdited && <input
                                    id='dns1'
                                    name='dns1'
                                    type="text"
                                    // readOnly
                                    defaultValue={DNS?.DNS1 || empty}
                                    ref={dns1Ref}
                                />}
                            </td>
                        </tr>
                        <tr className={classes.high}>
                            <td className={classes.title}>
                                <label htmlFor="dns2">
                                    DNS2:
                                </label>
                            </td>
                            <td className={classes.value}>
                                {!isEdited && <a href={`https://${DNS?.DNS2}` || null} target='_blank' rel='noreferrer'>{DNS?.DNS2 || empty}</a>}
                                {isEdited && <input
                                    type="text"
                                    id='dns2'
                                    name='dns2'
                                    // readOnly
                                    defaultValue={DNS?.DNS2 || empty}
                                    ref={dns2Ref}
                                />}
                            </td>
                        </tr>
                        <tr className={classes.high}>
                            <td className={classes.title}>
                                <label htmlFor="dns3">
                                    DNS3:
                                </label>
                            </td>
                            <td className={classes.value}>
                                {!isEdited && <a href={`https://${DNS?.DNS3}` || null} target='_blank' rel='noreferrer'>{DNS?.DNS3 || empty}</a>}
                                {isEdited && <input
                                    type="text"
                                    id='dns3'
                                    name='dns3'
                                    // readOnly
                                    defaultValue={DNS?.DNS3 || empty}
                                    ref={dns3Ref}
                                />}
                            </td>
                        </tr>
                        <tr className={classes.high}>
                            <td className={classes.title}>
                                <label htmlFor="dns4">
                                    DNS4:
                                </label>
                            </td>
                            <td className={classes.value}>
                                {!isEdited && <a href={`https://${DNS?.DNS4}` || null} target='_blank' rel='noreferrer'>{DNS?.DNS4 || empty}</a>}
                                {isEdited && <input
                                    type="text"
                                    id='dns4'
                                    name='dns4'
                                    // readOnly
                                    defaultValue={DNS?.DNS4 || empty}
                                    ref={dns4Ref}
                                />}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                Hosting
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingName'>
                                    Name:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='hostingName'
                                    name='hostingName'
                                    readOnly
                                    defaultValue={hosting?.name || empty}
                                    ref={hostingNameRef}
                                />
                            </td>
                        </tr>
                        <tr className={classes.high}>
                            <td className={classes.title}>
                                <label htmlFor='hostingUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                {!isEdited && <a href={hosting?.url || null} target='_blank' rel='noreferrer'>{hosting?.url || null}</a>}
                                {isEdited && <input
                                    id='hostingUrl'
                                    name='hostingUrl'
                                    type='url'
                                    // readOnly
                                    defaultValue={hosting?.url || empty}
                                    ref={hostingUrlRef}
                                />}
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='hostingUname'
                                    name='hostingUname'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.uname || empty}
                                    ref={hostingUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='hostingPass'
                                    name='hostingPass'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.pass || empty}
                                    ref={hostingPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingExpires'>
                                    Expires on:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='hostingExpires'
                                    name='hostingExpires'
                                    type='date'
                                    readOnly
                                    defaultValue={hosting?.expires? formatInputDate(hosting?.expires) : empty}
                                    ref={hostingExpiresRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                SSL
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslProvider'>
                                    Provider:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='sslProvider'
                                    name='sslProvider'
                                    readOnly
                                    defaultValue={SSL?.provider || empty}
                                    ref={sslProviderRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='sslUname'
                                    name='sslUname'
                                    type="text"
                                    readOnly
                                    defaultValue={SSL?.uname || empty}
                                    ref={sslUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='sslPass'
                                    name='sslPass'
                                    type="text"
                                    readOnly
                                    defaultValue={SSL?.pass || empty}
                                    ref={sslPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslExpires'>
                                    Expires on:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='sslExpires'
                                    name='sslExpires'
                                    type='date'
                                    readOnly
                                    defaultValue={SSL?.expires ? formatInputDate(SSL.expires): empty}
                                    ref={sslExpiresRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                Control Panel
                            </td>
                        </tr>
                        <tr className={classes.high}>
                            <td className={classes.title}>
                                <label htmlFor='controlUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                {!isEdited && <a href={hosting?.control_panel?.url || null} target='_blank' rel='noreferrer'>{hosting?.control_panel?.url || null}</a>}
                                {isEdited && <input
                                    id='controlUrl'
                                    name='controlUrl'
                                    type='url'
                                    // readOnly
                                    defaultValue={hosting?.control_panel?.url || empty}
                                    ref={controlUrlRef}
                                />}
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='controlUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='controlUname'
                                    name='controlUname'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.control_panel?.uname || empty}
                                    ref={controlUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='controlPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='controlPass'
                                    name='controlPass'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.control_panel?.pass || empty}
                                    ref={controlPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                FTP
                            </td>
                        </tr>
                        <tr className={classes.high}>
                            <td className={classes.title}>
                                <label htmlFor='ftpUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                {!isEdited && <a href={hosting?.FTP?.url || null} target='_blank' rel='noreferrer'>{hosting?.FTP?.url || null}</a>}
                                {isEdited && <input
                                    id='ftpUrl'
                                    name='ftpUrl'
                                    type='url'
                                    // readOnly
                                    defaultValue={hosting?.FTP?.url || empty}
                                    ref={ftpUrlRef}
                                />}
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='ftpUname'
                                    name='ftpUname'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.FTP?.uname || empty}
                                    ref={ftpUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='ftpPass'
                                    name='ftpPass'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.FTP?.pass || empty}
                                    ref={ftpPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpComments'>
                                    Comments:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='ftpComments'
                                    name='ftpComments'
                                    type="text"
                                    readOnly
                                    defaultValue={hosting?.FTP?.comments || empty}
                                    ref={ftpCommentsRef}
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                Comments
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.valueTextArea}>
                                <textarea
                                    className={classes.comments}
                                    id="comments"
                                    name="comments"
                                    readOnly
                                    defaultValue={comments}
                                    ref={commentsRef}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
                {isEdited && <button type='submit'>Save</button>}
            </form>
            <div className={classes.actions}>
                {!isEdited && <button onClick={editHandler}>Edit</button>}
                {!isEdited && <button onClick={removeHandler}>Remove</button>}
            </div>
        </div>
    )
};

export default CurrentDomainDetails;