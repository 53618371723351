import React, { useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import useDB from '../../../hooks/useDB';

import Grid from '../../Grid';
import GridItem from '../../GridItem';
import Search from '../../Search';
import DomainItem from '../../DomainItem';
import DomainDetails from '../../DomainDetails';
import AddNewDomain from '../../AddNewDomain';


import classes from './styles.module.css';

const AddDomain = () => {
    return (
        <div className={classes.item}>

            <div className={classes.add}>⊕</div>
        </div>
    );
};


const Domains = (props) => {
    const [showDetails, setShowDetails] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [currentDomain, setCurrentDomain] = useState(null);
    const [domains, setDomains] = useState([]);


    const auth = getAuth();
    const { readAll } = useDB();
    const fetchDomains = useCallback(async () => {
        console.log('fetching domains')
        onAuthStateChanged(auth, async user => {
            if (user) {
                try {
                    const domainsList = await readAll('domains', user.uid);
                    console.log(domainsList)
                    setDomains(domainsList);
                } catch (error) {
                    console.error(error);
                }
            }
        })
    }, []);

    useEffect(() => {
        fetchDomains();
    }, [fetchDomains]);
    

    const viewDetailsHandler = domain => {
        setCurrentDomain(domain);
        setShowDetails(true);
    };

    const closeModalHandler = () => {
        setCurrentDomain(null);
        setShowDetails(false);
        setAddNew(false);
        fetchDomains();
    };

    const addDomainHandler = () => {
        setAddNew(true);
    };

    const listClass = 'grid'; // or list - change dynamically
    return (
        <div className={classes.wrapper}>
            {showDetails && <DomainDetails domain={currentDomain} onClose={closeModalHandler} />}
            {addNew && <AddNewDomain onClose={closeModalHandler} />}
            <h1 className={classes.title}>Domains</h1>
            <Search />
            <Grid>
                {domains.map(domain => (
                    <GridItem onClick={() => viewDetailsHandler(domain)} key={domain.id} footer='View details &rarr;'>
                        <DomainItem domain={domain} />
                    </GridItem>
                ))}
                <GridItem onClick={addDomainHandler} key={'new'} footer='Add new +' new={true}>
                    <AddDomain />
                </GridItem>
            </Grid>
        </div>
    );
};

export default Domains;