import React, { useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import useDB from '../../../hooks/useDB';

import Grid from '../../Grid';
import GridItem from '../../GridItem';
import Search from '../../Search';
import SSLItem from '../../SSLItem';
import SSLDetails from '../../SSLDetails';
import AddNewSSL from '../../AddNewSSL';

import classes from './styles.module.css';

const AddSSL = () => {
    return (
        <div className={classes.item}>

            <div className={classes.add}>⊕</div>
        </div>
    );
};

const SSL = (props) => {
    const [showDetails, setShowDetails] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [currentDomain, setCurrentDomain] = useState(null);
    const [domains, setDomains] = useState([]);


    const auth = getAuth();
    const { readAll } = useDB();
    const fetchDomains = useCallback(async () => {
        console.log('fetching SSLs')
        onAuthStateChanged(auth, async user => {
            if (user) {
                try {
                    const domainsList = await readAll('domains', user.uid);
                    const nonEmptydomainsList = domainsList.filter(domain => domain.SSL.expires !== null && domain.SSL.expires !== '');
                    console.log(nonEmptydomainsList)
                    setDomains(nonEmptydomainsList);
                } catch (error) {
                    console.error(error);
                }
            }
        })
    }, []);

    useEffect(() => {
        fetchDomains();
    }, [fetchDomains]);

    const viewDetailsHandler = domain => {
        setCurrentDomain(domain);
        setShowDetails(true);
    };

    const addSSLHandler = () => {
        setAddNew(true);
        console.log('Select from domain list and add SSL details overwriting any existing data in the domain record');
    };

    const closeModalHandler = () => {
        setCurrentDomain(null);
        setShowDetails(false);
        setAddNew(false);
        fetchDomains();
    };

    const listClass = 'grid'; // or list - change dynamically

    return (
        <div className={classes.wrapper}>
            {showDetails && <SSLDetails domain={currentDomain} onClose={closeModalHandler} />}
            {addNew && <AddNewSSL onClose={closeModalHandler} />}
            <h1 className={classes.title}>SSL</h1>
            <Search />
            <Grid>
                {domains.map(domain => (
                    <GridItem onClick={() => viewDetailsHandler(domain)} key={domain.id} footer='View details &rarr;'>
                        <SSLItem domain={domain} />
                    </GridItem>
                ))}
                <GridItem onClick={addSSLHandler} key={'new'} footer='Add new +' new={true}>
                    <AddSSL />
                </GridItem>
            </Grid>
        </div>
    );
};

export default SSL;