import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Dashboardpage from './pages/Dashboardpage';
import Domainspage from './pages/Domainspage';
import SSLpage from './pages/SSLpage';
import Hostingpage from './pages/Hostingpage';
import Emailspage from './pages/Emailspage';
import Settingspage from './pages/Settingspage';
import Profilepage from './pages/Profilepage';
import Authpage from './pages/Authpage';
import AuthContext from './context/auth-context';

// import './App.css';

function App() {
  const authCtx = useContext(AuthContext);
  let routes = (<Routes>
    <Route path='/' element={<Authpage />} />
    <Route path='*' element={<Navigate replace to='/'/>} />
  </Routes>);
  if (authCtx.isLoggedIn) {
    routes = (
      <Routes>
        <Route path='/' element={<Dashboardpage />} />
        <Route path='dashboard' element={<Dashboardpage />} />
        <Route path='domains' element={<Domainspage />} />
        <Route path='ssl' element={<SSLpage />} />
        <Route path='hosting' element={<Hostingpage />} />
        <Route path='emails' element={<Emailspage />} />
        <Route path='settings' element={<Settingspage />} />
        <Route path='profile' element={<Profilepage />} />
        <Route path='*' element={<Navigate replace to='/' />} />
      </Routes>
    );
  } 
  
    return (
      <Layout>
          {routes}
      </Layout>
    );
};

export default App;
