export const formatDateTime = (date) => {
    // const date = new Date(timestamp);
    // console.log(date);
    // console.log(date.getTime());
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }).format(date);
};

export const formatMonth = (date) =>
    new Intl.DateTimeFormat('en-US', {
        month: 'long',
    }).format(date);

export const formatInputDate = date => {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    month = month.toString().padStart(2, '0');
    let day = date.getDate();
    day = day.toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const expire = (date) => {
    const assetDate = Date.parse(date);
    if (date && (assetDate - Date.now()) < (30 * 24 * 60 * 60 * 1000)) {
        return true;
    }
    return false;
}