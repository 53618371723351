import React, { useState, useEffect, useCallback } from 'react';


// let logoutTimer;

// helper functions
const getToken = () => {
    let token = localStorage.getItem('token');
    let uid = localStorage.getItem('uid');
    // let expirationTime;
    // if (token) {
    //     expirationTime = localStorage.getItem('expirationTime');
    //     if ((expirationTime - Date.now()) <= 60000) {
    //         token = null;
    //         localStorage.removeItem('token');
    //         localStorage.removeItem('expirationTime');
    //     }
    // }
    return { token, uid };
};

// const remainingTime = expiresIn => {
//     const now = Date.now();
//     const expirationDate = new Date(now + (expiresIn * 1000)).getTime();
//     return expirationDate - now;
// };

// context
const AuthContext = React.createContext({
    token: '',
    uid:'',
    isLoggedIn: false,
    login: (uid, token) => { },
    logout: () => { }
});

export const AuthContextProvider = props => {
    const initToken = getToken();
    const [token, setToken] = useState(initToken.token);
    const [uid, setUid] = useState(initToken.uid);
    const isLoggedIn = !!token;

    const logout = () => {
        setToken(null);
        setUid(null);
        localStorage.removeItem('token');
        localStorage.removeItem('uid');
        // logoutTimer && clearTimeout(logoutTimer);
    };

    const login = (uid, token) => {
        console.log('login initiated')
        setToken(token);
        setUid(uid);
        localStorage.setItem('token', token);
        localStorage.setItem('uid', uid);
        // const timeToExpire = remainingTime(expiresIn);
        // logoutTimer = setTimeout(logout, timeToExpire);
    };

    // useEffect(() => {
    //     if (initToken.token) {
    //         const timeToExpireExistingToken = initToken.expirationTime - Date.now();
    //         logoutTimer = setTimeout(logout, timeToExpireExistingToken);
    //     }
    // }, [initToken, logout]);

    const contextValue = {
        token,
        uid,
        isLoggedIn,
        login,
        logout
    };

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;