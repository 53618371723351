import { useState } from 'react';
import { getAuth } from 'firebase/auth'
import { collection, doc, addDoc, setDoc, deleteDoc, updateDoc, getDocs, getDoc, where, query, Timestamp } from "firebase/firestore";
import { db } from '../firebase';

const convertTimestamp = data => {
    if (data.SSL?.expires) data.SSL.expires = data.SSL.expires.toDate();
    if (data.registrar?.expires) {
        data.registrar.expires = data.registrar.expires.toDate();
    }
    if (data.expires) {
        data.expires = data.expires.toDate();
    }
    return data;
}
const useDB = () => {
    const [error, setError] = useState(null);
    const auth = getAuth();
    const user = auth.currentUser?.uid;
    // helper for creating the documents
    const saveDocument = async ({ collectionName, subCollectionName, docName, data }) => {
        docName = docName || null;
        console.log(collectionName, subCollectionName, docName)
        const docRef = doc(db, collectionName, 'assets', subCollectionName, docName);
        try {
            await setDoc(docRef, data);
        } catch (err) {
            console.error(err);
            setError(err);
            return null;
        }
        return docRef;
    };

    const deleteDocument = async ({ collectionName, subCollectionName, docName }) => {
        try {
            await deleteDoc(doc(db, collectionName, 'assets', subCollectionName, docName));   
        } catch (err) {
            console.error(err);
            setError(err);
        }
    }

    const readDocument = async (subCollectionName, docName) => {
        const docRef = doc(db, user, 'assets', subCollectionName, docName)
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            console.error('no document found!');
            setError(new Error('No document found!'));
        }
    }

    // create User collection
    const createUser = async ({ uid, email }) => {
        const ownerData = {
            uid,
            email
        };
        try {
            await setDoc(doc(db, uid, 'owner'), ownerData);
            await setDoc(doc(db, uid, 'settings'), {})
            await setDoc(doc(db, uid, 'assets'), {});

        } catch (err) {
            console.error(err);
            setError(err);
        }
    };


    const saveDomain = async domainDetails => {
        if (domainDetails.SSL.expires) domainDetails.SSL.expires = Timestamp.fromDate(new Date(domainDetails.SSL.expires));
        if (domainDetails.registrar.expires) domainDetails.registrar.expires = Timestamp.fromDate(new Date(domainDetails.registrar.expires));
        
        const data = {
            collectionName: user,
            subCollectionName: 'domains',
            docName: domainDetails.domainName,
            data: domainDetails
        }
        const docRef = await saveDocument(data);
        return docRef;
    };

    const removeDomain = (domainName) => {
        const data = {
            collectionName: user,
            subCollectionName: 'domains',
            docName: domainName
        };
        deleteDocument(data);
    };

    const readDomain = async domainName => {
        const data = await readDocument('domains', domainName);
        convertTimestamp(data);        
        return data
    }

    const readHosting = async (hostingRef, queryType) => {
        let ref = hostingRef;
        if (queryType === 'name') {
            ref = doc(db, user, 'assets', 'hosting', hostingRef);
        }
        const docSnap = await getDoc(ref);
        const data = docSnap.data();
        convertTimestamp(data);
        return data;
    }

    const saveHosting = async hostingDetails => {
        if (hostingDetails.expires) hostingDetails.expires = Timestamp.fromDate(new Date(hostingDetails.expires));

        const data = {
            collectionName: user,
            subCollectionName: 'hosting',
            docName: hostingDetails.name,
            data: hostingDetails
        }
        const docRef = await saveDocument(data);
        return docRef;
    };

    const removeHosting = hostingRef => {
        const data = {
            collectionName: user,
            subCollectionName: 'hosting',
            docName: hostingRef
        };
        deleteDocument(data);
    };

    const updateSSL = async sslDetails => {
        const { domainName, provider, expires, uname, pass } = sslDetails;
        const domainRef = doc(db, user, 'assets', 'domains', domainName);
        console.log(domainRef)
        const SSL = {
            provider,
            uname,
            pass,
            expires: Timestamp.fromDate(new Date(expires))
        };
        await updateDoc(domainRef, { SSL });
    };

    const removeSSL = async domainName => {
        const domainRef = doc(db, user, 'assets', 'domains', domainName);
        const SSL = {
            provider: null,
            uname: null,
            pass: null,
            expires: null
        };
        await updateDoc(domainRef, { SSL });
    };

    const saveEmail = emailDetails => {
        const data = {
            collectionName: user,
            subCollectionName: 'emails',
            docName: emailDetails.email,
            data: emailDetails
        }
        const docRef = saveDocument(data);
        return docRef;
    };

    const saveSettings = async ({ collectionName, data }) => {
        let docRef = null;
        try {
            docRef = await setDoc(doc(db, collectionName, 'settings'), data);
        } catch (err) {
            console.error(err);
            setError(err);
        }
        return docRef;
    };

    const readAll = async (collectionName, uid) => {
        const collectionArray = [];
        let err = null;
        const userId = user || uid;
        try {
            const querySnapshot = await getDocs(collection(db, userId, "assets", collectionName));
            querySnapshot.forEach(doc => {
                const data = doc.data();
                console.log(data)
                // if (collectionName === 'domains') convertTimestamp(data);
                convertTimestamp(data)
                collectionArray.push(data);
            });
        } catch (error) {
            err = error;
            console.error(error)
        }
        return new Promise((resolve, reject) => {
            resolve(collectionArray);
            reject(err)
        });
    };

    const readDashboard = async (collectionName, uid) => {
        const collectionArray = [];
        let err = null;
        const userId = user || uid;
        try {
            const collectionRef = collection(db, userId, 'assets', collectionName);
            const q = query(collectionRef, where('registrar.uname', '==', 'radek'));
            const querySnapshot = await getDocs(q);
            console.log(querySnapshot.docs.length)
            querySnapshot.forEach(doc => {
                collectionArray.push(doc.data());
            });
            console.log(collectionArray)
        } catch (error) {
            err = error;
        }
        return new Promise((resolve, reject) => {
            resolve(collectionArray);
            reject(err);
        })
    }
    return {
        saveDomain,
        saveHosting,
        saveEmail,
        saveSettings,
        createUser,
        readAll,
        readDomain,
        readHosting,
        readDashboard,
        removeDomain,
        removeHosting,
        removeSSL,
        updateSSL,
        error
    };
};

export default useDB;