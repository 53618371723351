import React, { useState, useRef } from 'react';
import useAuth from '../../../hooks/useAuth';

import classes from './styles.module.css';

const Auth = (props) => {
    const [isLogin, setIsLogin] = useState(true);
    const { login, isLoading, error } = useAuth();
    const emailInputRef = useRef();
    const passwordInputRef = useRef();

    const switchAuthModeHandler = () => {
        setIsLogin(prevState => !prevState);
    };


    const submitHandler = evt => {
        evt.preventDefault();
        const email = emailInputRef.current.value;
        const password = passwordInputRef.current.value;
        let type;

        if (isLogin) {
            type = 'login';
        } else {
            type = 'signup';
        }
        login({ email, password, type });
    };
    return (
        <div className={classes.wrapper}>
            <section className={classes.auth}>
                {isLoading && <p>LOADING...</p>}
                {error && <p>{error.message}</p>}
                <h1>{isLogin ? 'Login' : 'Sign Up'}</h1>
                <form onSubmit={submitHandler}>
                    <div className={classes.control}>
                        <label htmlFor='email'>Your Email</label>
                        <input type='email' id='email' ref={emailInputRef} required />
                    </div>
                    <div className={classes.control}>
                        <label htmlFor='password'>Your Password</label>
                        <input type='password' id='password' ref={passwordInputRef} required />
                    </div>
                    <div className={classes.actions}>
                        {!isLoading && <button>{isLogin ? 'Login' : 'Create Account'}</button>}
                        {isLoading && <p>Waiting for server response...</p>}
                        <button
                            type='button'
                            className={classes.toggle}
                            onClick={switchAuthModeHandler}
                        >
                            {isLogin ? 'Create new account' : 'Login with existing account'}
                        </button>
                    </div>
                </form>
            </section>
        </div>
    );
};

export default Auth;