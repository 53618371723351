import React from 'react';

import classes from './styles.module.css';

const Search = (props) => {
    
    return (
        <div className={classes.search}>
            <input type="search" id='search' name='search' />
            <label htmlFor="search">🔎</label>
        </div>
    );
};

export default Search;