import React, { useEffect, useRef, useState, useCallback } from 'react';
import useDB from '../../../hooks/useDB';
import { formatInputDate, expire } from '../../../services/Format/Date';


import classes from './styles.module.css';

const CurrentSSLDetails = props => {
    const [isEdited, setIsEdited] = useState(false);
    const [domain, setDomain] = useState(null);
    const { readDomain, updateSSL, removeSSL } = useDB();


    //refs for inputs
    const sslProviderRef = useRef();
    const sslUnameRef = useRef();
    const sslPassRef = useRef();
    const sslExpiresRef = useRef();


    let domainData = domain || props.domain;
    const { domainName } = props.domain;
    const { id, registrar, DNS, SSL, comments } = domainData;
    const populateData = useCallback(async () => {
        try {
            const domainDataServer = await readDomain(domainName);
            
            setDomain(domainDataServer);
            console.log('read from db')
        } catch (error) {
            console.error(error)
            setDomain(props.domain);
        }
    }, [domainName]);
    useEffect(() => {
        populateData();
    }, [populateData])
    const empty = '';

    const readDomainChanges = (id, domainName) => {
        const SSL = {
            provider: sslProviderRef.current.value,
            uname: sslUnameRef.current.value,
            pass: sslPassRef.current.value,
            expires: sslExpiresRef.current.value
        };
        return {
            id,
            domainName,
            ...SSL,
        };
    };

    const saveSSL = async details => {
        try {
            await updateSSL(details);
        } catch (error) {
            console.error(error);
        }
    };
    
    const submitHandler = e => {
        e.preventDefault();
        let inputs = [...document.getElementsByTagName('input'), ...document.getElementsByTagName('textarea')];
        inputs.forEach(input => {
            input.readOnly = true
        });
        const newSSLDetails = readDomainChanges(id, domainName);
        setIsEdited(false);
        // save to db
        saveSSL(newSSLDetails);
    };

    const editHandler = () => {
        let inputs = [...document.getElementsByTagName('input'), ...document.getElementsByTagName('textarea')];
        inputs.forEach(input => input.readOnly = false);
        setIsEdited(true);
    };
    const removeHandler = () => {
        removeSSL(domainName);
        props.onClose();
    };
    return (
        <div className={classes.details}>
            <form onSubmit={submitHandler}>
                <table>
                    <thead>
                        <tr>
                            <th colSpan='2'>{domainName}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr>
                            <td colSpan='2' className={classes.blockName}>
                                SSL
                            </td>
                        </tr> */}
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslProvider'>
                                    Provider:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='sslProvider'
                                    name='sslProvider'
                                    readOnly
                                    defaultValue={SSL?.provider || empty}
                                    ref={sslProviderRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='sslUname'
                                    name='sslUname'
                                    type="text"
                                    readOnly
                                    defaultValue={SSL?.uname || empty}
                                    ref={sslUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='sslPass'
                                    name='sslPass'
                                    type="text"
                                    readOnly
                                    defaultValue={SSL?.pass || empty}
                                    ref={sslPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslExpires'>
                                    Expires on:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    id='sslExpires'
                                    name='sslExpires'
                                    type='date'
                                    readOnly
                                    defaultValue={SSL?.expires ? formatInputDate(SSL.expires) : empty}
                                    ref={sslExpiresRef}
                                />
                            </td>
                        </tr>
                        
                        </tbody>
                </table>
                {isEdited && <button type='submit'>Save</button>}
            </form>
            <div className={classes.actions}>
                {!isEdited && <button onClick={editHandler}>Edit</button>}
                {!isEdited && <button onClick={removeHandler}>Remove</button>}
            </div>
        </div>
    )
};

export default CurrentSSLDetails;