import React from 'react';
import { formatDateTime, expire } from '../../services/Format/Date';

import classes from './styles.module.css';

const HostingItem = (props) => {
    const { hosting } = props;
    const date = hosting.expires ? formatDateTime(hosting.expires) : ' --/--/----';
    const isExpiring = expire(hosting.expires) ? classes.expiring : null;
    return (
        <div className={classes.item}>
            <div className={classes.hostingName}>
                <h1>{hosting.name}</h1>
            </div>
            <section className={classes.content}>
                <p>Name: <span>{hosting.name}</span></p>
                <p className={isExpiring}>Expires on: <span>{date}</span></p>
            </section>
        </div>
    );
};

export default HostingItem;