import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';


import useDB from '../../../hooks/useDB';
import classes from './styles.module.css';

const AddDomain = props => {
    const [domainsList, setDomainsList] = useState([]);
    const auth = getAuth();

    const { updateSSL, readAll } = useDB();

    const fetchDomains = useCallback(async () => {
        console.log('fetching domains')
        onAuthStateChanged(auth, async user => {
            if (user) {
                try {
                    const domains = await readAll('domains', user.uid);
                    console.log(domainsList)
                    const listOfDomains = domains.map(domain => domain.domainName);
                    console.log(listOfDomains);
                    setDomainsList(listOfDomains);
                } catch (error) {
                    console.error(error);
                }
            }
        })
    }, []);

    useEffect(() => {
        fetchDomains();
    }, [fetchDomains]);
    //refs for inputs
    const domainNameRef = useRef();
    const sslProviderRef = useRef();
    const sslUnameRef = useRef();
    const sslPassRef = useRef();
    const sslExpiresRef = useRef();

    const readDomainChanges = () => {
        const domainName = domainNameRef.current.value;
        const SSL = {
            provider: sslProviderRef.current.value,
            uname: sslUnameRef.current.value,
            pass: sslPassRef.current.value,
            expires: sslExpiresRef.current.value
        };
        return {
            id: domainName,
            domainName,
            ...SSL
        };
    };

    const saveSSL = async details => {
        try {
            await updateSSL(details);
        } catch (error) {
            console.error(error);
        }
    };

    const submitHandler = async e => {
        e.preventDefault();
        const SSLDetails = readDomainChanges();
        // ADD SOME VALIDATION

        // save to db
        await saveSSL(SSLDetails);
        props.onClose();
    };

    const listofDomains = domainsList.map(domain => (
        <option value={domain}>{domain}</option>
    ));

    return (
        <div className={classes.details}>
            <form onSubmit={submitHandler}>
                <table>
                    <thead>
                        <tr>
                            <th className={classes.title}>
                                <label htmlFor="domainName">Domain: </label>
                            </th>
                            <th className={classes.value}>
                                <select name='domainName' id='domainName' ref={domainNameRef}>
                                    <option value="">--Select a domain--</option>
                                    {listofDomains}
                                </select>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                SSL
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslProvider'>
                                    Name:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='sslProvider'
                                    name='sslProvider'
                                    ref={sslProviderRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='sslUname'
                                    name='sslUname'
                                    ref={sslUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='sslPass'
                                    name='sslPass'
                                    ref={sslPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslExpires'>
                                    Expires on:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='date'
                                    id='sslExpires'
                                    name='sslExpires'
                                    ref={sslExpiresRef}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type='submit'>Save</button>
            </form>
        </div>
    )
};

export default AddDomain;