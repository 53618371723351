import React, { useState, useCallback, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import useDB from '../../../hooks/useDB';

import Grid from '../../Grid';
import GridItem from '../../GridItem';
import Search from '../../Search';
import AddNewHosting from '../../AddNewHosting';
import HostingItem from '../../HostingItem';
import HostingDetails from '../../HostingDetails';

import classes from './styles.module.css';

const AddHosting = () => {
    return (
        <div className={classes.item}>

            <div className={classes.add}>⊕</div>
        </div>
    );
};


const Hosting = (props) => {
    const [showDetails, setShowDetails] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [currentHosting, setCurrentHosting] = useState(null);
    const [hostings, setHostings] = useState([]);


    const auth = getAuth();
    const { readAll } = useDB();
    const fetchHostings = useCallback(async () => {
        console.log('fetching hostings')
        onAuthStateChanged(auth, async user => {
            if (user) {
                try {
                    const hostingList = await readAll('hosting', user.uid);
                    console.log(hostingList)
                    setHostings(hostingList);
                } catch (error) {
                    console.error(error);
                }
            }
        })
    }, []);

    useEffect(() => {
        fetchHostings();
    }, [fetchHostings]);


    const viewDetailsHandler = hosting => {
        setCurrentHosting(hosting);
        setShowDetails(true);
    };

    const closeModalHandler = () => {
        console.log('closing')
        setCurrentHosting(null);
        setShowDetails(false);
        setAddNew(false);
        fetchHostings();
    };

    const addHostingHandler = () => {
        setAddNew(true);
    };

    const listClass = 'grid'; // or list - change dynamically
    return (
        <div className={classes.wrapper}>
            {showDetails && <HostingDetails hosting={currentHosting} onClose={closeModalHandler} />}
            {addNew && <AddNewHosting onClose={closeModalHandler} />}
            <h1 className={classes.title}>Hosting</h1>
            <Search />
            <Grid>
                {hostings.map(hosting => (
                    <GridItem onClick={() => viewDetailsHandler(hosting)} key={hosting.name} footer='View details &rarr;'>
                        <HostingItem hosting={hosting} />
                    </GridItem>))}
                <GridItem onClick={addHostingHandler} key={'new'} footer='Add new +' new={true}>
                    <AddHosting />
                </GridItem>
            </Grid>
        </div>
    );
};

export default Hosting;