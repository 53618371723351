import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import AuthContext from '../../context/auth-context';

import classes from './styles.module.css';

const Header = (props) => {
    const { logout } = useAuth();
    const authCtx = useContext(AuthContext);
    // const navigate = useNavigate();
    const logoutHandler = () => {
        logout();
        // navigate('/', {replace: true});
    }
    return (
        <header className={classes.header}>
            <div className={classes.logo}>
                <h1>
                    Domain Management System
                    <span className={classes.sup}>beta</span>
                </h1>
                
            </div>
            
            {authCtx.isLoggedIn && <nav>
                <ul>
                    <li><Link to='/profile'>👤</Link></li>
                    <li onClick={logoutHandler}>Logout</li>
                </ul>
            </nav>}
            {/* <span>dark/light mode</span> */}
        </header>
    );
};

export default Header;