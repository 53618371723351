import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, updatePassword, signOut } from 'firebase/auth';

import AuthContext from "../context/auth-context";
import useDB from "./useDB";

const useAuth = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const authCtx = useContext(AuthContext);

    const navigate = useNavigate();
    const auth = getAuth();
    const { createUser } = useDB();
    error && console.error(error)

    const login = ({ email, password, type }) => {
        setError(null)
        setIsLoading(true);
        let authFn = null;
        let createNewUser = () => null;
        
        if (type === 'login') {
            authFn = signInWithEmailAndPassword;
        } else if (type === 'signup') {
            authFn = createUserWithEmailAndPassword;
            createNewUser = createUser;
        }

        authFn(auth, email, password)
            .then(userCredential => {
                setIsLoading(false);
                // user signed in
                const user = userCredential.user;
                createNewUser({ uid: user.uid, email: user.email });
                authCtx.login(user.uid, user.accessToken);
                navigate('/', { replace: true });
            })
            .catch(err => {
                setIsLoading(false);
                const code = err.code;
                const message = err.message;
                setError({ code, message });
            });

    };

    const logout = () => {
        setIsLoading(true);
        signOut(auth)
            .then(() => {
                setIsLoading(false);
                console.log('user logged out');
                authCtx.logout();
                navigate('/', { replace: true });
            })
            .catch(err => {
                setIsLoading(false);
                const code = err.code;
                const message = err.message;
                setError({ code, message });
            });
    };

    const changePass = (newPassword) => {
        setIsLoading(true);
        const user = auth.currentUser;
        updatePassword(user, newPassword)
            .then(() => {
                setIsLoading(false);
                setSuccess(true);
                setTimeout(() => setSuccess(null), 2000)
            })
            .catch(err => {
                setIsLoading(false);
                const code = err.code;
                const message = err.message;
                setError({ code, message });
            });
    };

    return {
        login, logout, changePass, success, isLoading, error
    };

};

export default useAuth;

