// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAdUH-4D9ja15Sq0b9QOHnG3LWXGkQlcn8",
    authDomain: "domain-management-system-esar.firebaseapp.com",
    projectId: "domain-management-system-esar",
    storageBucket: "domain-management-system-esar.appspot.com",
    messagingSenderId: "173677373083",
    appId: "1:173677373083:web:b795300a3188aae22f483e"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);