import React, {useContext} from 'react';

import AuthContext from '../../context/auth-context';
import Sidebar from '../Sidebar';
import Header from '../Header';
import Footer from '../Footer';

import classes from './styles.module.css'

const Layout = (props) => {
    const authCtx = useContext(AuthContext);
    return (
        <div className={classes.container}>
            <Header />
            <div className={classes.content}>
                
                {authCtx.isLoggedIn && <Sidebar />}
                <main>{props.children}</main>
            </div>
            <Footer />
        </div>
    );
};

export default Layout;