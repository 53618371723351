import React from 'react';

import classes from './styles.module.css';

const DashboardItem = (props) => {
    
    return (
        <div className={classes.item}>
            <div className={classes.title}>
                <h1>{props.asset}</h1>
            </div>
            <section className={classes.content}>
                <p>{props.amount}</p>
            </section>
        </div>
    );
};

export default DashboardItem;