import React, {useRef} from 'react';
import useAuth from '../../../../hooks/useAuth';

import classes from './styles.module.css';

const ProfileForm = (props) => {
    const passRef = useRef();
    const { changePass, success, isLoading, error} = useAuth();

    const submitHandler = evt => {
        evt.preventDefault();
        const password = passRef.current.value;
        changePass(password);
    };

    return (
        <form className={classes.form} onSubmit={submitHandler}>
            {isLoading && <p className={classes.msg}>loading...</p>}
            {error && <p className={classes.error}>{error.message}</p>}
            {success && <p className={classes.success}>Password changed successfully!</p>}
            <div className={classes.control}>
                <label htmlFor='new-password'>New Password</label>
                <input type='password' id='new-password' minLength='7' ref={passRef} />
            </div>
            <div className={classes.action}>
                <button>Change Password</button>
            </div>
        </form>
    );
};

export default ProfileForm;