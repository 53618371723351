import React from 'react';

import classes from './styles.module.css';

const Footer = (props) => {
    
    return (
        <footer className={classes.footer}>
                &copy; 2022 <a href="https://room33.dev" target='_blank' rel='noreferrer'>Room33</a>
        </footer>
    );
};

export default Footer;