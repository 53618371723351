import React from 'react';
import Modal from '../Modal';
import AddHosting from './AddHosting';

import classes from './styles.module.css';

const AddNewHosting = (props) => {

    return (
        <Modal onClose={props.onClose}>
            <AddHosting onClose={props.onClose} />
        </Modal>
    );
};

export default AddNewHosting;