import React from 'react';
import Modal from '../Modal';
import CurrentHostingDetails from './CurrentHostingDetails';

import classes from './styles.module.css';

const HostingDetails = (props) => {

    return (
        <Modal onClose={props.onClose}>
            <CurrentHostingDetails hosting={props.hosting} onClose={props.onClose} />
        </Modal>
    );
};

export default HostingDetails;