import React, { useContext, useEffect, useRef, useState } from 'react';

import AuthContext from '../../../context/auth-context';
import useDB from '../../../hooks/useDB';
import classes from './styles.module.css';

const AddHosting = props => {
    const { saveDomain, saveHosting } = useDB();
    const authCtx = useContext(AuthContext);

    //refs for inputs
    const hostingNameRef = useRef();
    const hostingUrlRef = useRef();
    const hostingUnameRef = useRef();
    const hostingPassRef = useRef();
    const hostingExpiresRef = useRef();
    const controlUrlRef = useRef();
    const controlUnameRef = useRef();
    const controlPassRef = useRef();
    const ftpUrlRef = useRef();
    const ftpUnameRef = useRef();
    const ftpPassRef = useRef();
    const ftpCommentsRef = useRef();


    const readHostingChanges = () => {
        const control_panel = {
            url: controlUrlRef.current.value,
            uname: controlUnameRef.current.value,
            pass: controlPassRef.current.value
        };
        const FTP = {
            url: ftpUrlRef.current.value,
            uname: ftpUnameRef.current.value,
            pass: ftpPassRef.current.value,
            comments: ftpCommentsRef.current.value
        };
        return {
            id: hostingNameRef.current.value,
            name: hostingNameRef.current.value,
            url: hostingUrlRef.current.value,
            uname: hostingUnameRef.current.value,
            pass: hostingPassRef.current.value,
            expires: hostingExpiresRef.current.value,
            control_panel,
            FTP
        };
    };

    const saveDetails = async hosting => {
        try {
            await saveHosting(hosting);
        } catch (error) {
            console.error(error);
        }
        return;
    }

    const submitHandler = async e => {
        e.preventDefault();
        const hostingDetails = readHostingChanges();
        // ADD SOME VALIDATION

        // save to db
        await saveDetails(hostingDetails);

        props.onClose();
    };

    return (
        <div className={classes.details}>
            <form onSubmit={submitHandler}>
                <table>
                    <tbody>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                Hosting
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingName'>
                                    Name:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='hostingName'
                                    name='hostingName'
                                    ref={hostingNameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='url'
                                    id='hostingUrl'
                                    name='hostingUrl'
                                    ref={hostingUrlRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='hostingUname'
                                    name='hostingUname'
                                    ref={hostingUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='hostingPass'
                                    name='hostingPass'
                                    ref={hostingPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingExpires'>
                                    Expires on:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='date'
                                    id='hostingExpires'
                                    name='hostingExpires'
                                    ref={hostingExpiresRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                Control Panel
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='controlUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='url'
                                    id='controlUrl'
                                    name='controlUrl'
                                    ref={controlUrlRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='controlUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='controlUname'
                                    name='controlUname'
                                    ref={controlUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='controlPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='controlPass'
                                    name='controlPass'
                                    ref={controlPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                FTP
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='url'
                                    id='ftpUrl'
                                    name='ftpUrl'
                                    ref={ftpUrlRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='ftpUname'
                                    name='ftpUname'
                                    ref={ftpUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='ftpPass'
                                    name='ftpPass'
                                    ref={ftpPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpComments'>
                                    Comments:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='ftpComments'
                                    name='ftpComments'
                                    ref={ftpCommentsRef}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button type='submit'>Save</button>
            </form>
        </div>
    )
};

export default AddHosting;