import React, { useContext, useEffect, useRef, useState } from 'react';

import useDB from '../../../hooks/useDB';
import classes from './styles.module.css';

const AddDomain = props => {
    const { saveDomain, saveHosting } = useDB();

    //refs for inputs
    const domainNameRef = useRef();
    const registrarNameRef = useRef();
    const registrarUrlRef = useRef();
    const registrarUnameRef = useRef();
    const registrarPassRef = useRef();
    const registrarExpiresRef = useRef();
    const dns1Ref = useRef();
    const dns2Ref = useRef();
    const dns3Ref = useRef();
    const dns4Ref = useRef();
    const hostingNameRef = useRef();
    const hostingUrlRef = useRef();
    const hostingUnameRef = useRef();
    const hostingPassRef = useRef();
    const hostingExpiresRef = useRef();
    const sslProviderRef = useRef();
    const sslUnameRef = useRef();
    const sslPassRef = useRef();
    const sslExpiresRef = useRef();
    const controlUrlRef = useRef();
    const controlUnameRef = useRef();
    const controlPassRef = useRef();
    const ftpUrlRef = useRef();
    const ftpUnameRef = useRef();
    const ftpPassRef = useRef();
    const ftpCommentsRef = useRef();
    const commentsRef = useRef();


    const readDomainChanges = () => {
        const domainName = domainNameRef.current.value;
        const registrar = {
            name: registrarNameRef.current.value,
            url: registrarUrlRef.current.value,
            uname: registrarUnameRef.current.value,
            pass: registrarPassRef.current.value,
            expires: registrarExpiresRef.current.value
        };
        const DNS = {
            DNS1: dns1Ref.current.value,
            DNS2: dns2Ref.current.value,
            DNS3: dns3Ref.current.value,
            DNS4: dns4Ref.current.value
        };
        const SSL = {
            provider: sslProviderRef.current.value,
            uname: sslUnameRef.current.value,
            pass: sslPassRef.current.value,
            expires: sslExpiresRef.current.value
        };
        
        const comments = commentsRef.current.value;
        return {
            id: domainName,
            domainName,
            registrar,
            DNS,
            SSL,
            comments
        };
    };
    const readHostingChanges = () => {
        const control_panel = {
            url: controlUrlRef.current.value,
            uname: controlUnameRef.current.value,
            pass: controlPassRef.current.value
        };
        const FTP = {
            url: ftpUrlRef.current.value,
            uname: ftpUnameRef.current.value,
            pass: ftpPassRef.current.value,
            comments: ftpCommentsRef.current.value
        };
        return {
            id: hostingNameRef.current.value,
            name: hostingNameRef.current.value,
            url: hostingUrlRef.current.value,
            uname: hostingUnameRef.current.value,
            pass: hostingPassRef.current.value,
            expires: hostingExpiresRef.current.value,
            control_panel,
            FTP
        };
    };

    const saveDetails = async (domain, hosting) => {
        let hostingRef = null;
        try {
            if (hosting.name) {
                hostingRef = await saveHosting(hosting);
            }
            domain.hosting = hostingRef;
            await saveDomain(domain);
        } catch (error) {
            console.error(error);
        }
        return;
    }

    const submitHandler = async e => {
        e.preventDefault();
        const domainDetails = readDomainChanges();
        const hostingDetails = readHostingChanges();
        // ADD SOME VALIDATION

        // save to db
        await saveDetails(domainDetails, hostingDetails);
        props.onClose();
    };

    return (
        <div className={classes.details}>
            <form onSubmit={submitHandler}>
                <table>
                    <thead>
                        <tr>
                            <th className={classes.title}>
                                <label htmlFor="domainName">Domain: </label>
                            </th>
                            <th className={classes.value}>
                                <input
                                    type="text"
                                    id='domainName'
                                    name='domainName'
                                    ref={domainNameRef}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                Registrar
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='registrarName'>
                                    Name:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='registrarName'
                                    name='registrarName'
                                    ref={registrarNameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='registrarUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='url'
                                    id='registrarUrl'
                                    name='registrarUrl'
                                    ref={registrarUrlRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='registrarUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='registrarUname'
                                    name='registrarUname'
                                    ref={registrarUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='registrarPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='registrarPass'
                                    name='registrarPass'
                                    ref={registrarPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='RegistrarExpires'>
                                    Expires on:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='date'
                                    id='registrarExpires'
                                    name='registrarExpires'
                                    ref={registrarExpiresRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                DNS
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor="dns1">
                                    DNS1:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type="text"
                                    id='dns1'
                                    name='dns1'
                                    ref={dns1Ref}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor="dns2">
                                    DNS2:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type="text"
                                    id='dns2'
                                    name='dns2'
                                    ref={dns2Ref}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor="dns2">
                                    DNS3:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type="text"
                                    id='dns3'
                                    name='dns3'
                                    ref={dns3Ref}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor="dns4">
                                    DNS4:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type="text"
                                    id='dns4'
                                    name='dns4'
                                    ref={dns4Ref}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                Hosting
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingName'>
                                    Name:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='hostingName'
                                    name='hostingName'
                                    ref={hostingNameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='url'
                                    id='hostingUrl'
                                    name='hostingUrl'
                                    ref={hostingUrlRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='hostingUname'
                                    name='hostingUname'
                                    ref={hostingUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='hostingPass'
                                    name='hostingPass'
                                    ref={hostingPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='hostingExpires'>
                                    Expires on:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='date'
                                    id='hostingExpires'
                                    name='hostingExpires'
                                    ref={hostingExpiresRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                SSL
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslProvider'>
                                    Name:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='sslProvider'
                                    name='sslProvider'
                                    ref={sslProviderRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='sslUname'
                                    name='sslUname'
                                    ref={sslUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='sslPass'
                                    name='sslPass'
                                    ref={sslPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='sslExpires'>
                                    Expires on:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='date'
                                    id='sslExpires'
                                    name='sslExpires'
                                    ref={sslExpiresRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                Control Panel
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='controlUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='url'
                                    id='controlUrl'
                                    name='controlUrl'
                                    ref={controlUrlRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='controlUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='controlUname'
                                    name='controlUname'
                                    ref={controlUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='controlPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='controlPass'
                                    name='controlPass'
                                    ref={controlPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                FTP
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpUrl'>
                                    URL:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='url'
                                    id='ftpUrl'
                                    name='ftpUrl'
                                    ref={ftpUrlRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpUname'>
                                    Username:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='ftpUname'
                                    name='ftpUname'
                                    ref={ftpUnameRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpPass'>
                                    Password:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='ftpPass'
                                    name='ftpPass'
                                    ref={ftpPassRef}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.title}>
                                <label htmlFor='ftpComments'>
                                    Comments:
                                </label>
                            </td>
                            <td className={classes.value}>
                                <input
                                    type='text'
                                    id='ftpComments'
                                    name='ftpComments'
                                    ref={ftpCommentsRef}
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan='2' className={classes.blockName}>
                                Comments
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2' className={classes.valueTextArea}>
                                <textarea
                                    type='text'
                                    className={classes.comments}
                                    id="comments"
                                    name="comments"
                                    ref={commentsRef}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <button type='submit'>Save</button>
            </form>
        </div>
    )
};

export default AddDomain;