import React from 'react';
import Modal from '../Modal';
import AddDomain from './AddDomain';

import classes from './styles.module.css';

const AddNewDomain = (props) => {
    
    return (
        <Modal onClose={props.onClose}>
            <AddDomain onClose={props.onClose} />
        </Modal>
    );
};

export default AddNewDomain;