import React from 'react';
import Search from '../../Search';

import classes from './styles.module.css';

const Settings = (props) => {

    return (
        <div className={classes.wrapper}>
            <h1 className={classes.title}>Settings</h1>
            <Search />
        </div>
    );
};

export default Settings;