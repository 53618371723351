import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './styles.module.css';

const Sidebar = (props) => {
    let activeClassName = classes.active;
    return (
        <aside className={classes.sidebar}>
            <nav>
                <ul>
                    <li>
                        <NavLink 
                        to='dashboard' 
                        className={({ isActive }) => isActive ? activeClassName : ''}
                        >
                            <img src='images/dashboard.png' alt='icon' className={classes.logo} />Dashboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink 
                        to='domains' 
                        className={({ isActive }) => isActive ? activeClassName : ''}
                        >
                            <img src='images/domains.png' alt='icon' className={classes.logo} />Domains
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to='hosting'
                            className={({ isActive }) => isActive ? activeClassName : ''}
                        >
                            <img src='images/hosting.png' alt='icon' className={classes.logo} />Hosting
                        </NavLink>
                    </li>
                    <li>
                        <NavLink 
                        to='ssl' 
                        className={({ isActive }) => isActive ? activeClassName : ''}
                        >
                            <img src='images/ssl.png' alt='icon' className={classes.logo} />SSL
                        </NavLink>
                    </li>
                    <li>
                        <NavLink 
                        to='emails' 
                        className={({ isActive }) => isActive ? activeClassName : ''}
                        >
                            <img src='images/emails.png' alt='icon' className={classes.logo} />Emails
                        </NavLink>
                    </li>
                    <li>
                        <NavLink 
                        to='settings' 
                        className={({ isActive }) => isActive ? activeClassName : ''}
                        >
                            <img src='images/settings.png' alt='icon' className={classes.logo} />Settings
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </aside>
    );
};

export default Sidebar;