import React from 'react';
import Modal from '../Modal';
import AddSSL from './AddSSL';

import classes from './styles.module.css';

const AddNewDomain = (props) => {

    return (
        <Modal onClose={props.onClose}>
            <AddSSL onClose={props.onClose} />
        </Modal>
    );
};

export default AddNewDomain;