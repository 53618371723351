import React from 'react';
import Grid from '../../Grid';
import GridItem from '../../GridItem';
import Search from '../../Search';

import classes from './styles.module.css';

const Emails = (props) => {

    return (
        <div className={classes.wrapper}>
            {/* {showDetails && <EmailDetails domain={currentEmail} onClose={closeModalHandler} />} */}
            <h1 className={classes.title}>Emails</h1>
            <Search />
            <div>grid of emails with details inside, like: pop3/imap/mapi, uname, pass, aliases etc.</div>
            <Grid>
                {/* {emailList.map(domain => (
                    <GridItem onDetails={() => viewDetailsHandler(email)} key={email.id}>
                        <DomainItem email={email} />
                    </GridItem>))} */}
            </Grid>
        </div>
    );
};

export default Emails;