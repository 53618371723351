import React from 'react';

import classes from './styles.module.css';

const GridItem = (props) => {
    let click = null;
    if (props.new) click = props.onClick;
    return (
        <div className={classes.gridItem}>
            <div className={classes.gridContent} onClick={click}>
                {props.children}
            </div>
            <footer className={classes.footer} onClick={props.onClick}>
                {props.footer}
            </footer>
        </div>

    );
};

export default GridItem;