import React from 'react';

import ProfileForm from './ProfileForm';
import classes from './styles.module.css';

const UserProfile = (props) => {
    
    return (
        <div className={classes.wrapper}>
            <section className={classes.profile}>
                <h1>Change your password</h1>
                <ProfileForm />
            </section>
        </div>
    );
};

export default UserProfile;