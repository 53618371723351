import React from 'react';
import Modal from '../Modal';
import CurrentDomainDetails from './CurrentDomainDetails';

import classes from './styles.module.css';

const DomainDetails = (props) => {
    
    return (
        <Modal onClose={props.onClose}>
            <CurrentDomainDetails domain={props.domain} onClose={props.onClose} />
        </Modal>
    );
};

export default DomainDetails;